export const authActionTypes = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
};

export const blogActionTypes = {
  SET_POSTS: "SET_POSTS",
};

export const settingsActionTypes = {
  SET_LANGUAGE: "SET_LANGUAGE",
};
