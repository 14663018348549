import React from "react";
import LoginForm from "../../components/molecules/forms/LoginForm";

const LoginPage = () => {
  return (
    <>
      <LoginForm />
    </>
  );
};

export default LoginPage;
