import React from "react";
import Blog from "../../components/organisms/Blog";

// add sort option meta and via tags
// default by creation time
const BlogPage = () => {
  return (
    <>
      <Blog />
    </>
  );
};

export default BlogPage;
