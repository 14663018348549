import React from "react";

const AboutMe = () => {
  return (
    <>
      <h1>About me page :)</h1>
      <h3>
        Four secions: 1)about me + contact form + calendar? (simple fork from
        callendar.google), 2) about this page (github?), 3) in progress, 4)
        Honorable mentions?
      </h3>
    </>
  );
};

export default AboutMe;
