import React from "react";
import RegisterForm from "../../components/molecules/forms/RegisterForm";

const RegisterPage = () => {
  return (
    <>
      <RegisterForm />
    </>
  );
};

export default RegisterPage;
