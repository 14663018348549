import React from "react";

const BooksPage = () => {
  return (
    <>
      <h1>Books Page :)</h1>
      <h2>two sections: 1) read already, 2) to read</h2>
    </>
  );
};

export default BooksPage;
